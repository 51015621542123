import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout/layout"
import SEO from "../components/Layout/seo"
import { Section } from "../lib/utils"

const NotFoundPage = ({ data }) => {
  return (
    <Layout data={data}>
      <SEO title="404: Not found" />
      <Section>
        <h1>TIDAK DITEMUKAN</h1>
        <p>Maaf Halaman Yang Anda Cari Tidak Ditemukan ...</p>
      </Section>
    </Layout>
  )
}

export const query = graphql`
  query NotFoundPageQuery {
    site {
      siteMetadata {
        title
        description
        title
        address
        phone
        grabfoodLink
        gofoodLink
        facebook {
          label
          link
        }
        instagram {
          label
          link
        }
      }
    }
  }
`;

export default NotFoundPage;
